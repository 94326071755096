@charset "utf-8";
@import "./mixins";
@import "./variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@font-face {
 font-family: 'GraphikLCG-Regular';
 src: local('GraphikLCG-Regular'), url('./../fonts/GraphikLCG-Regular.woff2') format('woff2'), url('./../fonts/GraphikLCG-Regular.woff') format('woff');
 font-weight: normal;
 font-style: normal;
 font-display: swap;
}
@font-face {
 font-family: 'GraphikLCG-RegularItalic';
 src: local('GraphikLCG-RegularItalic'), url('./../fonts/GraphikLCG-RegularItalic.woff2') format('woff2'), url('./../fonts/GraphikLCG-RegularItalic.woff') format('woff');
 font-weight: normal;
 font-style: normal;
 font-display: swap;
}
@font-face {
 font-family: 'GraphikLCG-Medium';
 src: local('GraphikLCG-Medium'), url('./../fonts/GraphikLCG-Medium.woff2') format('woff2'), url('./../fonts/GraphikLCG-Medium.woff') format('woff');
 font-weight: normal;
 font-style: normal;
 font-display: swap;
}

@font-face {
 font-family: 'GraphikLCG-Semibold';
 src: local('GraphikLCG-Semibold'), url('./../fonts/GraphikLCG-Semibold.woff2') format('woff2'), url('./../fonts/GraphikLCG-Semibold.woff') format('woff');
 font-weight: normal;
 font-style: normal;
 font-display: swap;
}
@font-face {
 font-family: 'GraphikLCG-SemiboldItalic';
 src: local('GraphikLCG-SemiboldItalic'), url('./../fonts/GraphikLCG-SemiboldItalic.woff2') format('woff2'), url('./../fonts/GraphikLCG-SemiboldItalic.woff') format('woff');
 font-weight: normal;
 font-style: normal;
 font-display: swap;
}

html, body {
	height: 100%;
}
body {
	position: relative;
	margin: 0;
	padding: 40px 0 0 0;
	font-family: 'GraphikLCG-Regular', Arial, Helvetica, sans-serif;
	line-height:1.5;
	font-size: 15px;
	color: #4F4F4F;
	background: #FFF;
	@include media-breakpoint-up(lg) {
		font-size:16px;
	}
	@include media-breakpoint-up(xl) {
		font-size: 18px;
		padding:0;
	}
	@include media-breakpoint-up(xxl) {
		font-size: 20px;	
	}
}

h2, h3, h4, h5, .title-h2 {
 	font-family: 'GraphikLCG-Semibold';
	text-transform:uppercase;
	color: #1C1F22;
	margin: 0 0 20px 0;
	line-height: 1.2;
}

h1 {
	font-family: 'GraphikLCG-Semibold';
	text-transform:uppercase;
	color: #1c1c1c;
	font-size: 20px;
	line-height: 1.2;
	margin: 0;
	.main & {
		color:#FFF;
		margin-bottom:5px;
	}
	@include media-breakpoint-up(xs540) {	
		font-size: 22px;
	}
	@include media-breakpoint-up(sm) {	
		font-size: 24px;
	}
	@include media-breakpoint-up(lg) {	
		font-size: 26px;
	}
	@include media-breakpoint-up(xl) { 
		font-size: 30px;
		.main & {
			margin-bottom:20px
		}
	}
	@include media-breakpoint-up(xxl) { 
		font-size: 40px;
		.main & {
			font-size: 58px;
			margin-bottom:20px
		}
		
	}
}

h2, .title-h2 {
	font-size: 20px;
	margin: 0 0 10px 0;
	@include media-breakpoint-up(xl) {
		font-size: 26px;
		margin: 0 0 15px 0;
	}
	@include media-breakpoint-up(xxl) { 
		font-size: 32px;
		margin: 0 0 25px 0;
	}
	@include media-breakpoint-up(xlm) { 
		font-size: 40px;
		margin: 0 0 45px 0;
	}
}

h3 {
	font-size: 18px;
	margin: 0 0 10px 0;
	@include media-breakpoint-up(lg) { 
		font-size: 21px;
		margin: 0 0 15px 0;
	}
	@include media-breakpoint-up(lg) { 
		font-size: 25px;
		margin: 0 0 25px 0;
	}
	@include media-breakpoint-up(xxl) {
		font-size: 28px;
		margin: 0 0 35px 0;
	}
}

h4 {
	font-size: 18px;
	margin:5px 0 ;
	line-height: 1.2;
	@include media-breakpoint-up(lg) { 
		font-size: 20px;
		margin: 10px 0 10px 0;
	}
	@include media-breakpoint-up(xxl) {
		font-size: 24px;
	}
}

h5 {
	font-size: 20px;
	margin: 0 0 10px 0;
	text-transform: none;
}

h6 {
	font-size: 18px;
	line-height: 28px;
	margin: 0 0 10px 0;
}

p {
	margin: 0 0 10px 0;
	@include media-breakpoint-up(xl) {  
		margin: 0 0 20px 0;
	}
}
a {
	color: #282828;
	text-decoration: underline ;
	transition: all ease-out 0.2s;
	@include hover-focus {
		text-decoration:none;
		outline: none;
		color: #282828;
	}
}
b, strong {
	font-family: 'GraphikLCG-Semibold';
	font-weight:normal
}
fieldset {
	padding: .35em .625em .75em;
	margin: 0 2px;
	border: 1px solid #c0c0c0;
}
iframe {
	border:none
	}
input[type='submit']:focus, button:focus {
	border: none;
	outline: none
}

label {
	font-weight: normal
}

button {
	display: block;
}

img {
	max-width: 100%
}
.table {
	margin:0;
	font-size: 15px;
	@include media-breakpoint-up(xl) { 
		font-size: 17px;
	}	
}
.table th, .table td {
    padding:5px 10px;
	border-top:#E7E7E7 solid 1px;
	
}
.form-group {
	margin-bottom:25px;
}

.form-control {
	border: 1px solid #C4C4C4;
	font-size: 16px;
	color: #000;
	padding: 12px 20px;
	outline: none;
	border-radius: 2px;
	box-shadow: none;
	background-color: #FFF;
	height: inherit;
	font-family: 'GraphikLCG-Regular';
	&:focus {
		border: 1px solid #666;
		background-color: #fafafa;
		outline: hidden;
		box-shadow: none;
		@include transition (border-color ease-in-out .15s, box-shadow ease-in-out .15s);
	}
}
.form-control {
	::placeholder {
		color: #666666;
	}
}

input[type="file"] {
	background: transparent;
	border: none;
}

textarea.form-control, textarea.form-control:focus {
	height:inherit
}
.form-horizontal .control-label {
	padding-top: 5px; 
	text-align:right;
	font-size:16px;
}
input[type="file"] {
	background:transparent;
	border:none;
}
input[type="submit"]:focus {
	border:none;
	outline:hidden
}
.btn {
	font-size: 12px;
	background: #DE3232;
	line-height:1.1;
	padding:12px 20px;
	color:#FFF;
	text-transform:uppercase;
	text-decoration:none;
	border-radius:2px;
	display: block;
	width:100%;
	cursor: pointer;
	@include transition(all ease-out 0.2s);
	border:none;
	letter-spacing: 0.125em;
	font-family: 'GraphikLCG-Semibold';
	position:relative;
	&:hover, &:focus, &.active.focus, &.active:focus,  &active.focus, &active:focus{
		background:#F04949;
		box-shadow: none;
		color:#FFF;
		text-decoration: none;
		outline:hidden;
		outline-offset: none;
		outline: none;
		outline-offset:0;
	}
	&:focus, &.active.focus, &.active:focus,  &active.focus, &active:focus {
		background:#CF2727;
	}
	& + & {
		margin:10px 0 0 0;
	}
	@include media-breakpoint-up(lg) {
		display: inline-block;
		width: auto  ;
		& + & {
			margin:0 0 0 20px;
		}
	}
	@include media-breakpoint-up(xl) {
		font-size: 12px;
		padding:14px 25px;
	}
	@include media-breakpoint-up(xxl) {
		font-size: 15px;
		padding:22px 35px;
		
	}
}
.btn-primary {
	background-color:#58585A;
	&:hover, &:focus, &.active.focus, &.active:focus,  &active.focus, &active:focus {
		 background: #2b2b2b
		}
}
.error {
	color:#F00
}
.modal {
	overflow-y: auto;
}
.modal-open {
	overflow: auto;
}
input[type=radio], input[type=checkbox] {
	margin-top:7px;
}
.radio, .checkbox {
	margin-bottom:7px
}
.container {
	position:relative;
	@include media-breakpoint-up(sm) {
		 max-width: 540px;
	}
	 @include media-breakpoint-up(md){
		 max-width: 720px;
	}
	@include media-breakpoint-up(lg) {
		max-width: 960px; 
	}
	@include media-breakpoint-up(xl) {
		max-width: 1140px; 
	}
	@include media-breakpoint-up(xxl) {
		max-width: 1310px;
	}
	@include media-breakpoint-up(xlm) {
		max-width: 1430px;
	}
}
.breadcrumb {
	font-family: 'GraphikLCG-Regular';
	background-color:transparent;
	padding:0;
	color: #EB4343;
	margin-bottom: 7px;
	border-radius:0;
	font-size: 13px;
	line-height: 1.2;
	& a {
		text-decoration:none;
	}
	@include media-breakpoint-up(xlm) {
		margin-bottom: 30px;
		font-size: 16px;
	}
}
.breadcrumb-item  {
	& a {
	color: #EB4343;
	}
	&.active a {
		color: #494949;
	}
}
.breadcrumb-item {
	& + &:before {
		content: "";
		background:#C5C5C5;
		width:20px;
		top:50%;
		height:1px;
		display: block;
   		margin: 8px 10px 0 0;
	}
	@include media-breakpoint-up(lg) {
		& + &:before {
			margin: 10px 10px 0 0;
		}
	}
}
.form-container {
	margin:0 30px 20px 30px
}
label.error {
	margin:0;
	font-size:14px;
	line-height:1;
}
.navbar-expand-lg {
	float: left;
	padding: 0;
	margin: 0;
	background: #EB3A3A;
	@include media-breakpoint-up(lg) {
		& .navbar-toggler {
			display:block;
		}
	}
}
.navbar-collapse {
	position: fixed;
    z-index: 8010;
    top: 40px;
    bottom: 0;
    left: 0;
	width:80%;
	background:#EFF8FB;
	padding:20px 0 0 0;
	box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.1);
	flex-basis: auto  ;
    flex-grow: inherit;
    align-items: center;
}
.navbar-nav__wrap {
	width: 100%;
    overflow: auto;
    height: 100%;
}
.navbar-toggler {
	padding:13px 12px;
	border:none;
	& .icon-bar {
		display: block;
		width: 17px;
		height: 2px;
		border-radius: 0;
		background-color: #FFF;
		&:nth-child(2) {
			margin: 4px 0;
		}
	}
}
.navbar-toggler-close {
	position: absolute;
	top: 15px;
	right: 15px;
	margin: 0;
	background-color: transparent;
	border: none;
	width: 20px;
	height: 20px;
	& .icon-bar {
		display: block;
		width: 20px;
		height: 2px;
		border-radius: 2px;
		background-color: #cccccc;
		position:absolute;
		top:0;
		left:0;
		&:first-child  {
			transform: translate3d(0, 7px, 0) rotate(45deg);
		}
		&:last-child  {
			 transform: translate3d(0, -11px, 0) rotate(-45deg);
			top:18px;
		}
	}
}
.navbar-nav {
	list-style:none;
	padding:0;
	margin:0;
	text-transform: uppercase;
	font-family: 'GraphikLCG-Medium';
	font-size: 14px;
	line-height: 1.3;
	& a  {
		padding:10px 20px;
		color: #212121;
		display:block;
		text-decoration:none;
		@include hover-focus {
			color:#0077AA;
			text-decoration:none
		}
	}
	& .active > a {
		color:#DE3232;
	}
}
.navbar-nav__wrap-footer {
	border-top: 1px solid #DBDBDB;
	padding:20px 15px 15px 15px;
	margin-top:20px;
	& a {
		display:inline-block;
		vertical-align:middle
	}
	& .header__phone {
		margin:5px 0;
		font-size: 18px;
	}
	& .header__soc-footer  {
		margin:5px 0 5px 10px;
		width:20px;
		height:20px;
		background-position:center;
		background-repeat:no-repeat;
		background-size:20px;
	}
	& .header__tg-footer {
		background-image: url(../svg/telegram-footer.svg);
	}
	& .header__wa-footer {
		background-image: url(../svg/whatsapp-footer.svg);
	}
}
.menu__opened {
	height: 100%;
	overflow: hidden;
}
.overlay {
	background: rgba(0, 0, 0, 0.3);
	opacity: 1;
	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: auto;
	overflow-y: scroll;
}

header {
	position:relative;
}
.header-top  {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	height: 40px;
	background:#fff;
	box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.08);
}
.header__call {
	@include media-breakpoint-up(lg) {
		float:right;
		margin-top:10px;
	}
}

.header-info {
	height: 60px;
}

.logo {
	position: relative;
	width:150px;
	margin:7px 0 0 0;
	@include media-breakpoint-up(xl) {
		.main & {
			width:200px;
			margin:0 auto 20px auto;
			float:none;
			display:block;
		}
	}
	@include media-breakpoint-up(xxl) {
		.main & {
			width:238px;
			margin:0 auto 45px auto;
		}
	}

}
.header__phone {
	font-family: 'GraphikLCG-Medium';
	color:#1C1F22;
	float:left;
	text-decoration:none;
	&.header__soc {
		background-image: url(../svg/phone.svg);
	}
	@include hover-focus {
		color: #FF4646;
	}
	@include media-breakpoint-up(xl) {
		font-size:15px;
		line-height:60px;
		margin:0 10px 0 0;
	}
	@include media-breakpoint-up(xxl){
		font-size:17px;
		margin:0 15px 0 0;
	}
	@include media-breakpoint-up(xlm){
		font-size:18px;
		margin:0 55px 0 0;
	}
}
.header__soc {
	background-position:center;
	background-repeat:no-repeat;
	width:60px;
	height:60px;
	.header-top &{
		background-size:40px;
		width:40px;
		height:40px;
	}
}
.header__tg{
	background-color:#13B0D3;
	background-image:url(../svg/telegram.svg);
	&:hover {
		background-color:#29C4E7;
	}
	&:focus {	
		background-color:#11A1C0;
	}
}
.header__wa{
	background-color:#DE3232;
	background-image:url(../svg/whatsapp.svg);
	&:hover {
		background-color:#F04949;
	}
	&:focus {	
		background-color:#CF2727;
	}
}
.header__work {
	color:#5A5A5A;
	text-decoration:none;
	display:none;
	.navbar-nav__wrap-footer &{
		display:block;
	}
	@include media-breakpoint-up(xlg) {
		line-height:60px;
		font-size:18px;
		margin:0 45px 0 0;
		display:block;
	}
}
.nav-menu__wrap  {
	margin:0 -15px
}
.nav-menu {
	font-family: 'GraphikLCG-Medium';
	text-transform: uppercase;
	font-size: 12px;
	line-height: 60px;
	@include media-breakpoint-up(xl) {
		margin-left:10px;
		font-size: 13px;
	}
	@include media-breakpoint-up(xlm) {
		font-size: 14px;
	}
	@include media-breakpoint-up(xlg) {
		margin-left:105px;
		.header-page & {
			margin-left:30px;
		}
	}
}
.nav-item {
	margin: 0;
	display: inline-block;
	height: 60px;
	position: relative;		
}

.nav-link {
	text-decoration: none;
	display: inline-block;
	position: relative;
	color: #1C1F22;
	margin:0;
	padding:0 7px;
	@include hover-focus  {
		color:#0077AA;
		text-decoration: none;
		background: none;
	}
	.active & {
		color:#DE3232;
		text-decoration: none;
		background: transparent
	}
	@include media-breakpoint-up(xl){
		padding:0 5px;
	}
	@include media-breakpoint-up(xxl){
		padding:0 10px;
	}
	@include media-breakpoint-up(xlg) {
		padding:0 15px;
	}
}
footer {
	background:#101D28 url(../images/bg-footer.png);
	position:relative;
	text-align:center;
	color:#9A9A9A;
	& a {
		color:#FFF;
		text-decoration:none;
		border-bottom: 1px solid rgba(255, 255, 255, 0.38);
		@include hover-focus {
			color:#FFF;
			border-bottom: 1px solid rgba(255, 255, 255, 0);
		}
	}
	@include media-breakpoint-up(lg) {
		text-align:inherit;
	}
	@include media-breakpoint-up(xl) {
		padding:0;
		font-size:16px;
		& p {
			margin-bottom:15px;
		}
	}
}
.footer__top {
	padding:15px 0 5px 0;
	@include media-breakpoint-up(xl) {
		padding:30px 0;
	}
	@include media-breakpoint-up(xxl) {
		padding:65px 0;
	}
}
.footer__logo {
	float:left;
	display:none;
	@include media-breakpoint-up(xl) {
		width:15%;
		padding-right:20px;
		display:block;
	}
	@include media-breakpoint-up(xxl) {
		width:17%;
	}
	@include media-breakpoint-up(xlm) {
		width:19%;
	}
}
.footer__block {
	@include media-breakpoint-up(lg) {
		float:left;
		width:35%;
		padding-right:30px;
	}
	@include media-breakpoint-up(xxl) {
		width:22%;
	}
	@include media-breakpoint-up(xlm) {
		width:22%;
		padding-right:80px;
	}
}
.footer__soc-phone {
	& a {
		border:none;
		@include hover-focus {
			border:none;
		}
	}
	@include media-breakpoint-up(lg) {
			float:left;
			width:30%;
	}
	@include media-breakpoint-up(xl) {
			width:19%;
	}
	@include media-breakpoint-up(xxl) {
		width:17%;
	}
	@include media-breakpoint-up(xlm) {
		width:15%;
	}
}
.footer__menu {
	float:left;
	width:22%;
	list-style:none;
	padding:0;
	margin:0;
	font-size:15px;
	display:none;
	font-family: 'GraphikLCG-Medium';
	text-transform:uppercase;
	& li {
		margin-bottom:18px;
		& a {
			border:none;
			@include hover-focus {
				color:#E83F3F;
			}
		}
	}
	@include media-breakpoint-up(xl) {
		display:block;
	}
	@include media-breakpoint-up(xxl) {
		padding-right:30px;
	}
	@include media-breakpoint-up(xlm) {
		padding-right:80px;
	}
}
.footer__title {
	color:#F13333;
	font-family: 'GraphikLCG-Semibold';
	text-transform:uppercase;
	margin-bottom:17px;
	display:none;
	@include media-breakpoint-up(lg) {
		display:block;
	}
}
.footer__phone {
	margin-bottom:15px;
	& a {
		font-family: 'GraphikLCG-Medium';
		font-size:17px;
	}
	& span {
		display:block
	}
	@include media-breakpoint-up(xl) {
		margin-bottom:25px;
		padding-bottom:25px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.36);
		& a {
			font-size:22px;
		}
	}
}
.footer__soc-title  {
	font-family: 'GraphikLCG-Medium';
	margin-bottom:10px;
	color:#fff;
	@include media-breakpoint-up(xl) {
		font-size:17px;
		margin-bottom:15px;
	}
	
}
.footer__soc {
	display: inline-block;
	color:#E83F3F;
	text-transform:uppercase;
	font-family: 'GraphikLCG-Semibold';
	padding-left:30px;
	line-height:1.4;
	margin-bottom:15px;
	background-position: center left;
	background-repeat:no-repeat;
}
.footer__tel {
	background-image:url(../svg/telegram-footer.svg)
}
.footer__wha {
	background-image: url(../svg/whatsapp-footer.svg)
}
.footer-bottom {
	text-align:center;
	padding:15px 0;
	border-top: 1px solid rgba(255, 255, 255, 0.14);
	@include media-breakpoint-up(xl) {
		padding:30px 0;
	}
}

.footer-left {
	margin:0 0 10px 0;
	@include media-breakpoint-up(xl) {
		text-align:left;
		float:left;
		margin:0;
	}
}

.footer-right {
	margin:0 0 10px 0;
	@include media-breakpoint-up(xl) {
		float:right;
		margin:0;
	}
}

.scroll-top__btn {
	display: none;
	text-decoration: none;
	position: absolute;
	right:0;
	top:-80px;
	width: 80px;
	height: 80px;
	z-index:1000;
	background: url(../svg/up.svg) center no-repeat;
	@include media-breakpoint-up(xl) {
		display: block;
	}
	@include media-breakpoint-up(xlm) {
		top:-100px;
		width: 100px;
		height: 100px;
	}	
}

.main{
	background:#000 url(../images/bg-main.jpg) center  no-repeat;
	background-size: cover;
	text-align:center;
	padding:15px 0;
	color:#FFF;
	position:relative;
	& p {
		font-size:14px;
		line-height:1.4;
	}
	@include media-breakpoint-up(xl) {
		padding:30px 0;
		font-family: 'GraphikLCG-Medium';
		& p {
			font-size:16px;
			margin:0 auto;
			margin-bottom:25px;
		}
	}
	@include media-breakpoint-up(xxl) { 
		padding:50px 0 170px 0;
		& p {
			margin-bottom:50px;
			max-width:780px;
			font-size:24px;
		}
	}
}
.main__wrap {
	margin:0 auto;
	@include media-breakpoint-up(xs410) { 
		max-width:420px;
	}
	@include media-breakpoint-up(xl) { 
		max-width:600px;
	}
	@include media-breakpoint-up(xxl) { 
		max-width:900px;
	}
}
.header__text-over {
	position: absolute;
    left: 0;
	top:0;
	bottom: 0;
	border-right: 1px solid rgba(255, 255, 255, 0.3);
	width:100px;
    z-index: 10;
	color:#FFF;
	& span {
    	position: absolute;
		bottom:0;
		left:0;
   		display: block;
    	width: 100px;
    	height:80px;
		background:url(../svg/scroll.svg) center no-repeat;
		& i {
			font-style:normal;
			position:absolute;
			transform:rotate(-90deg);
			font-size:14px;
			letter-spacing: 0.435em;
			text-transform:uppercase;
			text-align:center;
			z-index:10;
			left:3px;
			top:-100px;					
		}	
	}
	@include hover-focus {
		color:#FFF;
	}	
	@include media-breakpoint-up(xlm) {
		width:120px;
		& span {
			width: 120px;
    		height:116px;
			& i {
				left:18px;
			}
		}
	}	
}
.main__icon {
	position: absolute;
    right: 0;
	top:0;
	bottom: 0;
	border-left: 1px solid rgba(255, 255, 255, 0.3);
	width:120px;
    z-index: 10;
	width:120px;
	background:url(../svg/icon-1.svg) right bottom no-repeat;
}
.aircraft {
	padding:15px 0;
	& h2 {
		display:none;
	}
	@include media-breakpoint-up(xl) {
		padding:30px 0;
		& h2 {
			display:block;
			margin-bottom:20px;
		}
	}
	@include media-breakpoint-up(xxl) { 
		padding:50px 0 70px 0;
		& h2 {
			margin-bottom:40px;
		}
	}
	@include media-breakpoint-up(xlm) { 
		padding:70px 0 90px 0;
		& h2 {
			margin-bottom:60px;
		}
	}	
}
.aircraft-wrap {
	margin-bottom:15px;
	@include media-breakpoint-up(md) {
		border-top: 1px solid #DBDBDB;
		border-bottom: 1px solid #DBDBDB;
	}
	@include media-breakpoint-up(xl) {
		font-size:18px;
		margin-bottom:35px;
	}
	@include media-breakpoint-up(xlm) { 
		margin-bottom:55px;
	}
}
.aircraft-tabs {
	list-style:none;
	padding:0;
	margin:0;
    display: flex;
    flex-wrap: wrap;
	border:none;
	& li {
		width:50%
	}
	@include media-breakpoint-up(md) {
		& li {
			width: inherit;
		}
	}
}
.aircraft-button {
	cursor:pointer;
	color:#6A6A6A;
	font-size:11px;
	padding:3px 0;
	font-family: 'GraphikLCG-Medium';
	text-decoration:none;
	display: block;
	&.active {
		color:#DF4C4C;
	}
	@include media-breakpoint-up(xs360) {
		font-size:12px;
	}
	@include media-breakpoint-up(xs410) {
		text-transform: uppercase;
		padding:5px 0;
	}
	
	@include media-breakpoint-up(md) {
		padding:10px 0;
		margin: 0 20px 0 0;
		&.active {
			color:#1C1F22;
			border-bottom:#DF4C4C solid 6px;
			padding-bottom:4px;
		}
	}
	@include media-breakpoint-up(xl) {
		font-size:16px;
		padding:15px 0;
		margin:0 40px 0 0;
		&.active {
			padding-bottom:11px;
		}
	}
	@include media-breakpoint-up(xxl) {
		font-size:18px;
		padding:20px 0;
		margin:0 60px 0 0;
		&.active {
			padding-bottom:14px;
		}
	}
}
.aircrafts-slider__button__wrap {
	display: none;
	position:relative;
	.aircrafts-slider__button {
		content: '';
		position: absolute;
		display: inline-block;
		background-color: transparent;
		border: none;
		width: 49px;
		height: 27px;
		text-indent: -90000%;
		margin: 0;
		cursor: pointer;
		&.aircrafts-slider__button-prev {
			background: url(../svg/arrow-back.svg) no-repeat;
			@include hover-focus {
				background: url(../svg/arrow-back_h.svg) no-repeat;
			}
		}
		&.aircrafts-slider__button-next {
			background: url(../svg/arrow-forward.svg) no-repeat;
			@include hover-focus {
				background: url(../svg/arrow-forward_h.svg) no-repeat;
			}
		}
		@include hover-focus {
			opacity: 0.9;
		}
	}
	@include media-breakpoint-up(xl) { 
		display: block;
		.aircrafts-slider__button {
			top: -150px;
			&.aircrafts-slider__button-prev {
				left: inherit;
				right:100px;
			}
			&.aircrafts-slider__button-next {
				left: inherit;
				right: 0;
			}
		}
	}
	@include media-breakpoint-up(xxl) { 
		.aircrafts-slider__button {
			top: -180px;
		}
	}
	@include media-breakpoint-up(xlm) { 
		.aircrafts-slider__button {
			top: -220px;
		}
	}
	@include media-breakpoint-up(xld) { 
		.aircrafts-slider__button {
			top: 100px;
			&.aircrafts-slider__button-prev {
				left: -80px;
				right:inherit;
			}
			&.aircrafts-slider__button-next {
				right: -80px;
			}
		}
	}
	@include media-breakpoint-up(xlg) {
		.aircrafts-slider__button {
			top: 100px;
			&.aircrafts-slider__button-prev {
				left: -120px;
			}
			&.aircrafts-slider__button-next {
				right: -120px;
			}
		}
	}
}
.aircraft__item {
	text-decoration:none;
}
.aircraft__inner-block {
	position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
}
.aircraft__inner-wrap {
	margin:0 -10px 0 0;
	@include media-breakpoint-up(sm) {
		margin:0;
	}
}
.aircraft__item-block {
	text-decoration:none;
	width:50%;
	padding:0 10px 0 0;
	@include media-breakpoint-up(sm) {
		width:248px;
		margin:0 15px 0 0;
		padding:0;
	}
	@include media-breakpoint-up(md) {
		width:220px;
		margin:0 15px 0 0;
	}
	@include media-breakpoint-up(lg) {
		width:297px;
		margin:0 20px 0 0;
	}
	@include media-breakpoint-up(xl) {
		width:255px;
		margin:0 30px 0 0;
	}
	@include media-breakpoint-up(xxl) {
		width:290px;
		margin:0 40px 0 0;
	}
	@include media-breakpoint-up(xlm) {
		width:320px;
		margin:0 40px 0 0;
	}
}
.aircraft__img {
	margin-bottom:7px;
	position: relative;
	overflow: hidden;
	display:block;
	background:#000;
	& img {
		display: block;
	    opacity: 1;
		transition: all 1000ms;
	}
	&:before {
		display:block;
		content:'';
		position:absolute;
		bottom:0;
		right:0;
		background: url(../svg/more-l.svg) center no-repeat;
		background-size:cover;
		width:30px;
		height:30px;
		z-index:10;
	}
	@include hover-focus {  
		& img {
			transform:scale(1.2, 1.2);
			opacity: 0.9;
		}
		&:before {
			display:block;
		}
	}
	@include media-breakpoint-up(lg) {
		margin-bottom:10px;
	
	}
	@include media-breakpoint-up(xl) {
		margin-bottom:15px;
		&:before {
			width:40px;
			height:40px;
		}
	
	}
}
.aircraft__title {
	color:#1C1F22;
	font-family: 'GraphikLCG-Medium';
	transition: all ease-out 0.2s;
	line-height:1.2;
	a:hover & {
		color:#DF4C4C;
	}
	@include media-breakpoint-up(lg) {
		margin-bottom:2px;
	
	}
	@include media-breakpoint-up(xl) {
		margin-bottom:5px;
		font-size:19px;
	
	}
}
.aircraft__number {
	color: #656565;
	font-size:14px;
	line-height:1;
	transition: all ease-out 0.2s;
	margin-top:3px;
	a:hover & {
		color:#000;
	}
}
.about {
	background:#EFF8FB url(../svg/star.svg) 97% 50px no-repeat;
	position:relative;
	padding:15px 0 5px 0;
	& h2 {
		padding-bottom:43px;
		margin-bottom:53px;
		position:relative;
		display:none;
		&:before {
			content: '';
			position: absolute;
			display: block;
			left:0; 
			bottom:0;
			width:73px;
			height:5px;
			background:#DF4C4C;
		}
	}
	@include media-breakpoint-up(xl) {
		padding:30px 0;
		& h2 {
			padding-bottom:20px;
			margin-bottom:25px;
			display:block;
		}
	}
	@include media-breakpoint-up(xxl) {
		padding:50px 0 70px 0;
		& h2 {
			padding-bottom:43px;
			margin-bottom:53px;
		}
	}
	@include media-breakpoint-up(xlm) {
		padding:100px 0 140px 0;
	}
	@include media-breakpoint-up(xld) {
		background-position:90% 50px;
	}
}
.about__text-over {
	position:absolute;
	top:0;
	bottom:0;
	left:0;
	width:120px;
	background:#FFF;
    z-index: 10;
	display:none;
	& span {
		display:block;
		position:absolute;
		transform: rotate(-90deg);
		font-size: 14px;
		color: #686868;
		letter-spacing: 0.435em;
		text-transform: uppercase;
		left: -80px;
		top: 200px;
		width: 285px;
	}
	@include media-breakpoint-up(xld) {
		display:block;
	}	
}
.about__left {
	@include media-breakpoint-up(xl) {
		width:50%;
	}
	@include media-breakpoint-up(xxl) {
		background:url(../svg/line.svg) right repeat-y;
		padding-right:50px;
	}
	@include media-breakpoint-up(xlm) {
		width:650px;
		padding-right:80px;
	}
}
.about__left-top {
	font-size: 17px;
	font-family: 'GraphikLCG-Semibold';
	line-height:1.3;
	@include media-breakpoint-up(lg) {
		font-size: 20px;
	}
	@include media-breakpoint-up(xl) {
		position:relative;
		background:#DF4C4C url(../images/bg-about.png) ;
		width:100%;
		height:554px;
		background-size: cover;
		color:#FFF;
		padding:70px 70px 0 70px;
		margin-bottom:20px;
		font-size: 32px;
	}
	@include media-breakpoint-up(xxl) {
		font-size: 34px;
		width:560px;
		height:554px;
		margin-bottom:75px;
	}
}
.about__left-st{
	font-size: 16px;
	font-family: 'GraphikLCG-Medium';
	line-height:1.3;
	@include media-breakpoint-up(lg) {
		font-size: 18px;
	}
	@include media-breakpoint-up(xl) {
		font-size: 22px;
		margin-bottom:20px;
	}
	@include media-breakpoint-up(xxl) {
		font-size: 28px;
		margin-bottom:60px;
	}
}
.about__left__text-over {
	position:absolute;
	bottom:66px;
	left:70px;
	font-size:16px;
	text-transform:uppercase;
	letter-spacing: 0.225em;
	text-transform: uppercase;
	display:none;
	&:before {
		content: "";
		display: block;
		position: absolute;
		bottom:55px;
		right:0;
		left:315px;
		height:1px;
		background:rgba(255, 255, 255, 0.52);
	}
	@include media-breakpoint-up(xxl) {
		display:block;
	}	
}
.about__right {
	@include media-breakpoint-up(xl) {
		width:50%;
		padding:0 0 0 50px;
	}
	@include media-breakpoint-up(xlm) {
		width:750px;
		padding:0 0 0 75px;
	}
}
.about__right-top {
	margin-bottom:15px;
	@include media-breakpoint-up(xl) {
			margin-bottom:25px;
	}
	@include media-breakpoint-up(xxl) {
			margin-bottom:70px;
	}
	@include media-breakpoint-up(xlm) {
			margin-bottom:105px;
	}
}
.about__img {
	margin-bottom:15px;
	@include media-breakpoint-up(xld) {
		background:url(../images/bg-img.png) top right no-repeat;
		margin:-50px -50px 0 0;
		padding:50px 50px 0 0;	
	}
}
.about__more {
	display: none;
	line-height:61px;
	background:#DF4C4C;
	padding:0 95px 0 45px;
	color:#FFF;
	text-transform:uppercase;
	letter-spacing: 0.125em;
	font-family: 'GraphikLCG-Semibold';
	font-size:15px;
	position:relative;
	text-decoration:none;
	&:before {
		transition: all ease-out 0.2s;
		content: "";
		display: block;
		position: absolute;
		top:0;
		right:0;
		width:61px;
		height:61px;
		background:#0CB4EA url(../svg/detail.svg);
	}
	&:hover {
		background:#F04949;
		color:#FFF;
		&:before {
			background-color:#29C4E7;
		}
	}
	&:focus {
		background:#CF2727;
		color:#FFF;
		&:before {
			background-color:#11A1C0;
		}
	}
	@include media-breakpoint-up(xl) {
		display: inline-block;
	}
}
.advantages {
	position:relative;
	padding:15px 0 5px 0;
	color:#FFF;
	background:#1aabca;
	& h2 {
		margin:0 0 30px 0;
		display:none;
		text-transform:uppercase;
		color:#FFF;
	}
	@include media-breakpoint-up(xl) {
		padding:30px 0 50px 0;
		background-image:url(../images/bg-plus.jpg);
		background-repeat:no-repeat;
		background-position:top center ;
		& h2 {
			display:block;
		}	
	}
	@include media-breakpoint-up(xxl) {
		padding:50px 0 80px 0;
	}
	@include media-breakpoint-up(xlm) {
		padding:100px 0 185px 0;
		background:#FFF;
		& h2 {
			margin:0;
			padding-left:55px;
			line-height:107px;
			border-bottom:1px solid rgba(255, 249, 249, 0.32);
		}
	}
}
.advantages__over {
	position:absolute;
	top:0;
	bottom:0;
	left:0;
	width:120px;
	display:none;
	background: url(../images/bg-plus_left.png);
	@include media-breakpoint-up(xlg) {
		display:block;
	}
}

.advantages__text-over {
	position:absolute;
	top:0;
	bottom:0;
	right:0;
	width:100px;
    z-index: 10;
	display:none;
	border-left: 1px solid #CACACA;;
	& span {
		display:block;
		position:absolute;
		transform: rotate(-90deg);
		font-size: 14px;
		color: #686868;
		letter-spacing: 0.435em;
		text-transform: uppercase;
		right: 0;
		bottom: 200px;
		width: 90px;
	}
	@include media-breakpoint-up(xlg) {
		display:block;
	}
}
.advantages__wrap {
	position:relative;
	&:before {
		content: "";
		display: none;
		position: absolute;
		top:0;
		left:0;
		width:108px;
		height:108px;
		background: url(../svg/plus.svg);
	}
	@include media-breakpoint-up(xlm) {
		background:#1aabca url(../images/bg-plus.jpg) top center no-repeat;
		background-size: cover;
		padding:0 0 0 108px;
		&:before {
			display:block;
		}
	}
}
.advantages__list {
	list-style:none;
	padding:0;
	margin:0;
	line-height:1.4;
	& li {
		position:relative;
		margin-bottom:20px;
		&:before {
			content: "";
			display: block;
			position: absolute;
			background-position:center;
			background-repeat:no-repeat;
			width:60px;
			height:60px;
			background-size:60px;
			left:0;
			top:28px;
		}
		&:nth-child(1):before {
			background-image:url(../svg/icon-2.svg)
		}
		&:nth-child(2):before {
			background-image:url(../svg/icon-3.svg)
		}
		&:nth-child(3):before {
			background-image:url(../svg/icon-4.svg)
		}
		&:nth-child(4):before {
			background-image:url(../svg/icon-5.svg)
		}
	}
	@include media-breakpoint-up(lg) {
		& li {
			width:25%;
			padding:0 25px 0 0;
			margin:0;
			&:before {
				width:140px;
				height:141px;
				background-size:140px;
				top:62px;
				left:0;
			}
		}
	}
	@include media-breakpoint-up(xl) {
		font-size:17px;
		& li {
			padding:0 50px 0 0;
			&:before {
				top:82px;
				left:0;
			}
		}
	}
	@include media-breakpoint-up(xlm) {
		& li {
			min-height:670px;
			padding:45px 28px 0 28px;
			&:after {
				content: "";
				display: block;
				position: absolute;
				top:0;
				bottom:0;
				left:0;
				width:1px;
				background:rgba(255, 249, 249, 0.32);
			}
			&:before {
				top:132px;
				left:28px
			}
		}
	}
}
.advantages__title {
	display:block;
	font-family: 'GraphikLCG-Semibold';
	font-size:18px;
	line-height:1.2;
	margin-bottom:5px;	
	@include media-breakpoint-up(lg) {
		margin-bottom:180px;
	}
	@include media-breakpoint-up(xl) {
		font-size:24px;
		margin-bottom:200px;		
	}
	@include media-breakpoint-up(xlm) {
		margin-bottom:240px;
	}
}
.advantages__note {
	padding-left:75px;
	min-height:60px;
	@include media-breakpoint-up(lg) {
		padding:0;
	}
}
	
.content__logo {
	background-color:#000000;
	background-repeat:no-repeat;
	background-position:center;
	background-size: cover;
	padding:15px 0;
	& .logo {
		margin:0 auto;
		width:238px;
	}
	@include media-breakpoint-up(xlm) {
		padding:25px 0 40px 0;
	}
}
.content__header-1 {
	background-image:url(../images/bg-header-1.jpg);
}
.content__header-2 {
	background-image:url(../images/bg-header-2.jpg);
}
.content__header-3 {
	background-image:url(../images/bg-header-3.jpg);
}
.content__header {
	text-align:center;
	padding:15px 0;
	@include media-breakpoint-up(xlm) {
		padding:30px 0;
	}
	@include media-breakpoint-up(xlm) {
		padding:40px 0 50px 0;
	}
}
.about-footer  {
	position:relative;
	padding:15px 0 5px 0;
	@include media-breakpoint-up(xl) {
		padding:30px 0 50px 0;
	}
	@include media-breakpoint-up(xxl) {
		padding:50px 0 80px 0;
	}
	@include media-breakpoint-up(xlm) {
		padding:100px 0 185px 0;
	}
}
.about-footer__left {
	@include media-breakpoint-up(xl) {
		width:50%;
	}
	@include media-breakpoint-up(xlm) {
		width:650px;
		padding:0 30px 0 0 ;
	}
}
.about-footer__right {
	@include media-breakpoint-up(xl) {
		width:50%;
		padding:0 0 0 50px;
	}
	@include media-breakpoint-up(xlm) {
		width:750px;
		padding:0 0 0 75px;
	}
}

.about-footer__img {
	margin-bottom:15px;
	@include media-breakpoint-up(xld) {
		background:url(../images/bg-img-2.png) bottom left no-repeat;
		margin:0 0 -50px -50px;
		padding:0 0 50px 50px ;	
	}
}
.contact {
	padding-bottom:5px;
	@include media-breakpoint-up(xl) {
		border-top:#BCBCBC solid 1px;
		padding:30px 0;
	}
	@include media-breakpoint-up(xlm) {
		padding:70px 0 85px;
	}
}
.contact__list {
	margin:0;
	padding:0;
	list-style:none;
	font-family: 'GraphikLCG-Medium';
	li {
		padding-left:65px;
		margin-bottom:15px;
		position:relative;
		min-height:50px;
		&:before {
			content: "";
			display: block;
			position: absolute;
			top:0;
			left:0;
			width:50px;
			height:50px;
			background-repeat:no-repeat;
			background-position:center;
			background-size: cover;
		}
		&:nth-child(1) {
			&:before {
				background-image:url(../svg/map-icon.svg);
			}
		}
		&:nth-child(2) {
			&:before {
				background-image:url(../svg/work-icon.svg);
			}
		}
		&:nth-child(3) {
			&:before {
				background-image:url(../svg/phone-icon.svg)
			}
		}
	}
	& a {
		text-decoration:none;
	}
	@include media-breakpoint-up(xl) {
		li {
			margin:0;
			padding-left:100px;
			&:before {
				width:70px;
				height:70px;
			}
		}
	}
}
.contact-footer {
	position:relative;
	padding:0 0 5px 0;
	& a {
		text-decoration:none;
	}
	@include media-breakpoint-up(xl) {
		padding:30px 0 50px 0;
	}
	@include media-breakpoint-up(xxl) {
		padding:50px 0 80px 0;
	}
	@include media-breakpoint-up(xlm) {
		padding:100px 0 185px 0;
	}
}
.contact-staff {
	background:#EFF8FB;
	color:#181818;
	padding:15px;
	margin:0 -15px;
	margin-bottom:15px;
	 font-size: 16px;
		@include media-breakpoint-up(xl) {
		border-left:#EB3A3A solid 7px;
		padding:30px;
		margin:0 0 30px 0;
	}
	@include media-breakpoint-up(xlm) {
		padding:45px 55px;
		margin-bottom:100px;
	}	
}
.contact-staff__item {
	margin-top:15px;
	& a {
		display:inline-block;
		vertical-align:middle;
		padding:3px;
	}
	@include media-breakpoint-up(xl) {
		margin:0;
	}
}
.contact-staff__item-name {
	color:#000000;
	margin-bottom:5px;
	font-family: 'GraphikLCG-Medium';
}
.contact-staff__note {
	@include media-breakpoint-up(xl) {
		font-size:18px;
		width:40%
	}
}
.contact-soc {
	& a {
		font-family: 'GraphikLCG-Medium';
	}
}
.contact-soc__item {
	margin-bottom:20px;
	@include media-breakpoint-up(xl) {
	width: calc(100% / 3);
		&:nth-child(1) {
			padding:0 60px 0 0;
		}
		&:nth-child(2) {
			background: url(../svg/line.svg)  left no-repeat, url(../svg/line.svg)  right no-repeat ;
			padding:0 60px;
		}
		&:nth-child(3) {
			padding:0 0 0 60px;
		}
	}
}
.contact-soc__mail {
	color:#1E77B8
}
.contact-soc__title {
	font-family: 'GraphikLCG-Medium';
	color:#1C1F22;
	margin-bottom:7px;
	font-size:18px;
	@include media-breakpoint-up(xl) {
		font-size:26px;
		margin-bottom:30px;
	}
}
.contact-soc__bot {
	margin-bottom:10px;
	color:#000000;
	display:block;
	& img {
		margin-right:10px;
	}
}
.contact-soc__group{
	margin-bottom:10px;
	& a {
		color:#E83F3F;
		margin-bottom:10px;
		text-decoration:none;
		border-bottom: 1px solid rgba(232, 63, 63, 1);
		@include hover-focus {
			color:#E83F3F;
			border-bottom: 1px solid rgba(232, 63, 63, 0);
		}
	}
}
.fleet__wrap {
	padding-bottom:5px;
	@include media-breakpoint-up(xl) {
		border-top:#BCBCBC solid 1px;
		padding:30px 0;
	}
	@include media-breakpoint-up(xlm) {
		padding:70px 0 85px;
	}
}
.fleet__inner {
	margin:0 -10px 0 0;
	@include media-breakpoint-up(sm) {
		margin:0 -7px;
	}
	@include media-breakpoint-up(md) {
	}

	@include media-breakpoint-up(lg) {
		margin:0 -10px;
	}
	@include media-breakpoint-up(xl) {
		margin:0 -15px;
	}
	@include media-breakpoint-up(xxl) {
		margin:0 -20px;
	}
	
}

.fleet__item{
	text-decoration:none;
	width:50%;
	padding:0 10px 20px 0;
	float:left;
	@include media-breakpoint-up(sm) {
		width:248px;
		margin:0 7px 15px 7px;
		padding:0;
	}
	@include media-breakpoint-up(md) {
		width:220px;
	}
	@include media-breakpoint-up(lg) {
		width:296px;
		margin:0 10px 20px 10px;
	}
	@include media-breakpoint-up(xl) {
		width:350px;
		margin:0 15px 30px 15px;
	}
	@include media-breakpoint-up(xxl) {
		width:400px;
		margin:0 20px 40px 20px;
	}
	@include media-breakpoint-up(xlm) {
		width:440px;
	}
}
.fleet__img {
	margin-bottom:7px;
	position: relative;
	overflow: hidden;
	display:block;
	background:#000;
	& img {
		display: block;
	    opacity: 1;
		transition: all 1000ms;
	}
	&:before {
		display:block;
		content:'';
		position:absolute;
		bottom:0;
		right:0;
		background: url(../svg/more-l.svg) center no-repeat;
		background-size:cover;
		width:40px;
		height:40px;
		z-index:10;
	}
	&:hover, &:focus  {
		& img {
			transform:scale(1.2, 1.2);
			opacity: 0.9;
		}
		&:before {
			display:block;
		}
	}
	@include media-breakpoint-up(lg) {
		margin-bottom:19px;
	
	}
	@include media-breakpoint-up(xl) {
		margin-bottom:15px;
		&:before {
			width:60px;
			height:60px;
		}
	
	}
}
.fleet__title {
	color:#1C1F22;
	font-family: 'GraphikLCG-Medium';
	transition: all ease-out 0.2s;
	a:hover & {
		color:#DF4C4C;
	}
	@include media-breakpoint-up(lg) {
		margin-bottom:2px;
	
	}
	@include media-breakpoint-up(xl) {
		margin-bottom:5px;
		font-size:22px;
	
	}
}
.fleet__number {
	color: #656565;
	font-size:12px;
	line-height:1;
	transition: all ease-out 0.2s;
	a:hover & {
		color:#000;
	}
	@include media-breakpoint-up(xl) {
		font-size:14px;
	}
}
.plane__wrap {
	padding-bottom:5px;
	@include media-breakpoint-up(xl) {
		border-top:#BCBCBC solid 1px;
		padding:30px 0;
	}
	@include media-breakpoint-up(xlm) {
		padding:70px 0 85px 0;
	}
}
.plane__inner {
	padding-bottom:5px;
	@include media-breakpoint-up(xl) {
		padding:0 0 30px;
	}
	@include media-breakpoint-up(xlm) {
		padding:0 0 70px 0;
	}
}
.plane__img{
		margin:0 0 15px 0;
	@include media-breakpoint-up(sm) {
		width:52%;
		padding:0 10px 0 0;
		}
	@include media-breakpoint-up(lg) {
		padding:0 15px 0 0;
	}
	@include media-breakpoint-up(xl) {
		padding:0 20px 0 0;
	}
	@include media-breakpoint-up(xxl) {
		padding:0 30px 0 0;
	}
	@include media-breakpoint-up(xlm) {
		padding:0 40px 0 0;
	}
}
.plane__note{
	margin:0 0 15px 0;
	@include media-breakpoint-up(sm) {
		width:48%;
		padding:0 0 0 10px;
		}
	@include media-breakpoint-up(lg) {
		width:48%;
		padding:0 0 0 15px ;
	}
	@include media-breakpoint-up(xl) {
		padding:0 0 0 20px ;
	}
	@include media-breakpoint-up(xxl) {
		padding:0 0 0 30px ;
	}
	@include media-breakpoint-up(xlm) {
		padding:0  0 0 40px;
		& .btn {
			margin-top:30px;
		}
	}
}
.plane__salon {
	padding-bottom:5px;
	margin:0 -5px;
	@include media-breakpoint-up(lg) {
		margin:0 -10px;
		}
	@include media-breakpoint-up(xl) {
		padding:0 0 10px 0;
		margin:0 -15px;
	}
	@include media-breakpoint-up(xlm) {
		padding:0 0 50px 0;
	}
}
.plane__salon-item {
	float:left;
	padding:0 5px;
	margin-bottom:10px;
	width:50%;
	height: 150px;
    overflow: hidden;
	& img {
		height:150px;
	}
	@include media-breakpoint-up(lg) {
		padding:0 10px 20px 10px;
		margin-bottom:0;
		overflow:inherit;
		width:inherit;
		& img {
			height: inherit;
		}
	}
	@include media-breakpoint-up(xl) {
		padding:0 15px 30px 15px;
	}
}
.plane__spec-fb {
	padding-bottom:5px;
	@include media-breakpoint-up(xl) {
		padding:0 0 30px;
	}
	@include media-breakpoint-up(xlm) {
		padding:0 0 70px 0;
	}
}
.plane__fb{
	margin:0 0 15px 0;
	& a {
		font-family: 'GraphikLCG-Medium';
		text-decoration:none;
		@include hover-focus {
			color: #FF4646;
		}
		& img {
			margin:0 3px 0 5px;
			vertical-align:middle;
		}
	}
	@include media-breakpoint-up(sm) {
		width:40%;
		padding:0 10px 0 0;
		}
	@include media-breakpoint-up(lg) {
		width:35%;
		padding:0 15px 0 0;
	}
	@include media-breakpoint-up(xl) {
		padding:0 20px 0 0;
	}
	@include media-breakpoint-up(xxl) {
		padding:0 30px 0 0;
	}
	@include media-breakpoint-up(xlm) {
		padding:0 40px 0 0;
	}
}
.plane__spec{
	margin:0 0 15px 0;
	& table {
		margin-bottom:15px;
		& td {
			border-bottom: 1px solid #C7C7C7;
			padding:5px 5px 5px 0;
		}
	}
	& img {
		margin:0 auto;
	}
	@include media-breakpoint-up(sm) {
		width:60%;
		padding:0 0 0 10px;
		}
	@include media-breakpoint-up(lg) {
		width:65%;
		padding:0 0 0 15px ;
	}
	@include media-breakpoint-up(xl) {
		padding:0 0 0 20px ;
			& table {
			margin-bottom:20px;
			& td {
				padding:7px 10px 7px 0;
			}
		}
	}
	@include media-breakpoint-up(xxl) {
		padding:0 0 0 30px ;
	}
	@include media-breakpoint-up(xlm) {
		padding:0  0 0 40px;
		& table {
			margin-bottom:40px;
		}
	}
}
.plane__fb-title {
	font-family: 'GraphikLCG-Medium';
	color:#1C1F22;
	font-size:18px;
	@include media-breakpoint-up(xl) {
		font-size:20px;
		padding-bottom:10px;
		margin-bottom:15px;
		border-bottom: 1px solid #C4C4C4;
	}
	@include media-breakpoint-up(xxl) {
		padding-bottom:15px;
		margin-bottom:20px;
	 	font-size:24px;
	}
	@include media-breakpoint-up(xlm) {
		padding-bottom:25px;
		margin-bottom:30px;
		font-size:28px;
	}
}
.plane__spec-title {
	font-family: 'GraphikLCG-Medium';
	color:#1C1F22;
	font-size:18px;
	line-height:1.2;
	@include media-breakpoint-up(xl) {
		font-size:20px;
		padding-bottom:10px;
		border-bottom: 2px solid #1C1F22;
	}
	@include media-breakpoint-up(xxl) {
		padding-bottom:15px;
	 	font-size:24px;
	}
	@include media-breakpoint-up(xlm) {
		padding-bottom:25px;
		font-size:28px;
	}
}
.about__footer {
	background-image: none;
}

.page-404 {
	padding-bottom: 40px;
}
.ul-list {
	margin: 0 0 25px 20px;
    padding: 0;
    list-style: none;
	& li {
		margin-bottom: 10px;
		padding-left: 40px;
		position: relative;
		&:before {
			content: "—";
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}